import { graphql, Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { SEO } from "~/components/SEO";
import { useStringsContext } from "~/contexts/strings-context";
import { PageProps } from "~/types/global";
import Pedagogy from "~/assets/svg/pedagogy";

export const query = graphql`
    query($language: String) {
        strings(language: { eq: $language }) {
            ...LayoutStringsFragment
            varqaschool
          }
    }
`;

const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  gap: 2rem;
  margin: 5rem 0;
  @media(max-width: 1300px) {
      flex-direction: column-reverse;
      margin-bottom: 3rem;
      height: auto;
      padding: 0 1.2rem;
    }
`;
const Text = styled.h1`
  font-size: 1.3rem;
  color: whitesmoke;
  font-family: "Cinzel";
  line-height: 2rem;
  letter-spacing: 1px;
  padding-top: .5rem;
  list-style-type: upper-roman;
`;

const TextContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  max-width: 50vw;
  @media(max-width: 1300px) {
    max-width: 100vw;
    }
    ol {
      list-style-type: square;
      color: whitesmoke;
    }
    li {
      ::marker {
        font-size: 1.2rem;
      }
    }
`;

const TitlePedagogy = styled.h1`
    display: flex;
    line-height: 2.7rem;
    text-align: start;
    font-size: 1.8rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #f8f8f8;
    align-items: center;
    flex-wrap: nowrap;
    min-width: 100%;
`;

const SubTitlePedagogy = styled(TitlePedagogy)`
  font-size: 1.3rem;
  line-height: 2rem;
  padding: 1rem 0;
`;

const MissionPage: React.FunctionComponent<PageProps> = (props) => {
  const strings = useStringsContext();
  return (
    <>
      <SEO
        lang={props.pageContext.language}
        title="Varqa School - Mission"
        description="Varqa School &amp; Junior College is one of the most prestigious schools in Ahmednagar District, having been around for more than 40 years. The school is in an area that is rich in greenery and is surrounded by an extremely healthy and open environment. Our school is also partnered with Cambridge University to provide our students with more rich content, and the school is committed to maintaining highly-trained and innovative faculty."
        keywords={["varqa", "school", "varqa school", "varka", "vaka", "varkha", "varqa school", "college", "ahmenagar", "ahmednagar", "maharashtra", "best school", "schools near me", "schol", "schoole", "schools", "varqa", "best", "top schools", "cbse", "cbse school", "state board", "state", "english medium", "school medium", "english school", "english", "ramin", "ruhiya", "saba", "sir", "madam", "ahmednagr best school", "nagar best school", "nagar", "school in nagar", "nagar school"]}
        url={props.location.pathname}
      />
      <Container>
        <Pedagogy/>
        <TextContainer>
          <TitlePedagogy>Our Pedagogy</TitlePedagogy>
          <SubTitlePedagogy>Varqa  Schools do not follow any particular school of pedagogy exclusively. The schools use those ideas that are time tested and applicable to the school and particular child group needs. The teachers at Varqa Schools always strive to :</SubTitlePedagogy>
        <ol>
          <li>
            <Text>Promote creativity, discovery and exploration.</Text>
          </li>
          <li>  
            <Text>Be patient and supportive.</Text>
          </li>
          <li>
            <Text>Encourage children to take initiative in selecting activities.</Text>
          </li>
          <li>
            <Text>Understand the individual capabilities of children.</Text>
          </li>
          <li>
            <Text>Interact with children by talking and listening to them.</Text>
          </li>
          <li>
            <Text>Allow children to do be independent and do things for themselves.</Text>
          </li>
          <li>
            <Text>Offer choices of activities and materials.</Text>
          </li>
          <li>
            <Text>Set and enforce reasonable limits.</Text>
          </li>
          <li>
            <Text>Be willing to use different methods of care to meet each child’s abilities and need.</Text>
          </li>
        </ol>
        </TextContainer> 
      </Container>
    </>
  )
}

export default MissionPage
